import { useEffect, useRef } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import isEqual from 'lodash.isequal';
import { SysLink } from 'contentful-management';

type ChangeEventValue = Array<SysLink> | undefined;

/**
 * React hook for detecting field change events in Contenful
 * Events are often duplicated so this ensures one call per valid data change event.
 */
export const useFieldChangeHook = (
  sdk: FieldExtensionSDK,
  callback: (next: ChangeEventValue) => void
) => {
  const previous = useRef(sdk.field.getValue());

  useEffect(() => {
    const unsubscribe: () => void = sdk.field.onValueChanged(
      (next: ChangeEventValue = []) => {
        // If field is an array, Contentful updates the field twice- first with the item removed, second to undefined.
        // When this is the case, it would perform two value changes where we can discard the second.
        if (
          (Array.isArray(previous.current) &&
            !previous.current.length &&
            next === undefined) ||
          (Array.isArray(next) &&
            !next.length &&
            previous.current === undefined)
        )
          return;

        const hasChange = !isEqual(previous.current, next);
        previous.current = next;

        if (hasChange) callback(next);
      }
    );

    return () => unsubscribe();
  }, [sdk.field, callback]);
};
