import React from 'react';
import { CustomEntityCardProps, DefaultCardRenderer, MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { SettingsIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { EntryProps, PlainClientAPI, SysLink } from 'contentful-management';
import { useFieldChangeHook } from '../Field.hooks';
import { Loading, Item } from './Variant.sku';
import { findAsync, patchEntryFieldReferences } from '../../utilities';
import {
  EntityList,
  TextInput,
  Card,
  Paragraph,
  Text,
  SkeletonContainer,
  SkeletonBodyText,
  Button,
  Stack,
  Checkbox,
  Asset,
  Menu,
  IconButton,
} from '@contentful/f36-components';
import { LinkActionsProps } from '@contentful/field-editor-reference/dist/components';

import './Product.variants.css';

const sillyQuotes = [
  'did you feed your cats today?',
  'when will we re-release leggings already???',
  'you look nice today!',
  'have a good day!',
  "we don't talk about Bruno, no, no, no"
];

const endpoint = 'https://platform.bombas.com/graphql';
// const endpoint = 'http://localhost:3000/dev/graphql';

interface ProductVariantsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntryPropsWithCustomMetaData = EntryProps & { custom: Record<string, any>; };

enum BulkAction {
  Publish = 'Publish',
  Replicate = 'Replicate',
}

enum ReplicateAction {
  Swatch = 'Swatch',
  VariantImages = 'VariantImages',
}

interface ReplicateActionData {
  actions: Array<ReplicateAction>;
  fromId: string;
}

/** Render the card */
const RenderCustomCardHook = (
  props: CustomEntityCardProps,
  linkActionProps: LinkActionsProps,
  renderDefaultCard: DefaultCardRenderer,
  linkedEntries: undefined | Array<EntryPropsWithCustomMetaData>,
  sdk: FieldExtensionSDK,
  bulkEditIds: Array<string>,
  setBulkEditIds: (id: Array<string>) => void,
  runBulkAction: (action: BulkAction, data: ReplicateActionData | undefined) => void,
  bulkActionsRunning: boolean,
) => {
  const entity = props.entity;
  const id = entity.sys.id;
  const skuLocales = (entity.fields as Record<string, Record<string, string>>)?.sku;
  const sku = (skuLocales && skuLocales[sdk.locales.default]) || 'this';

  let image = '';
  const entityWithCustomMetadata = linkedEntries?.find((e) => e.sys.id === id);
  if (entityWithCustomMetadata) {
    image = entityWithCustomMetadata.custom?.swatchAsset?.fields?.file[sdk.locales.default].url || image;
  }

  const hasImages = typeof entityWithCustomMetadata?.custom?.images !== 'undefined';

  return (
    <Stack style={{ gap: '0', position: 'relative', alignItems: 'stretch' }}>
      <Checkbox
        className="bombas-variant-checkbox"
        value={id}
        id={`option-${id}`}
        isChecked={bulkEditIds.includes(id)}
        onChange={(e) => {
          const target: HTMLInputElement = e.target as HTMLInputElement;
          if (target.checked === true) {
            setBulkEditIds([...bulkEditIds, id]);
          } else {
            setBulkEditIds(bulkEditIds.filter(i => i !== id));
          }
        }}
      />
      <div className="bombas-variant-swatch">
        <Asset
          type="image"
          src={image}
        />
      </div>
      {renderDefaultCard(props)}
      <div className="bombas-variant-menu">
        <Menu>
          <Menu.Trigger>
            <IconButton
              isDisabled={bulkActionsRunning === true}
              variant="secondary"
              icon={<SettingsIcon />}
              aria-label="Toggle menu"
              size="small"
              style={{
                padding: '.25rem',
              }}
            />
          </Menu.Trigger>
          <Menu.List>
            <Menu.SectionTitle>Bulk Edit</Menu.SectionTitle>
            <Menu.Item
              disabled={image === ''}
              onClick={() => runBulkAction(
                BulkAction.Replicate,
                { actions: [ReplicateAction.Swatch], fromId: id }
              )}
            >
              Replicate Swatch
            </Menu.Item>
            <Menu.Item
              disabled={!hasImages}
              onClick={() => runBulkAction(
                BulkAction.Replicate,
                { actions: [ReplicateAction.VariantImages], fromId: id }
              )}
            >
              Replicate Images
            </Menu.Item>
            <Menu.Item
              disabled={image === '' || !hasImages}
              onClick={() => runBulkAction(
                BulkAction.Replicate,
                { actions: [ReplicateAction.Swatch, ReplicateAction.VariantImages], fromId: id }
              )}
            >
              Replicate Images &amp; Swatch
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                Data will be replicated to checked
                <br/>variants from:
              </Text>
            </Menu.Item>
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                {sku}
              </Text>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </div>
    </Stack>
  );
}

/** Field editor for Product.variants */
const ProductVariantsField = ({ sdk, cma }: ProductVariantsFieldProps) => {
  const [loadingState, setLoadingState] = React.useState<Loading>(Loading.None);
  const [query, setQuery] = React.useState('');
  const [items, setItems] = React.useState<Array<Item>>([]);
  const [linkedEntries, setLinkedEntries] = React.useState<undefined | Array<EntryPropsWithCustomMetaData>>(undefined);
  const [filteredItems, setFilteredItems] = React.useState<Array<Item>>([]);
  const [bulkEditIds, setBulkEditIds] = React.useState<Array<string>>([]);
  const [bulkActionsRunning, setBulkActionsRunning] = React.useState<boolean>(false);

  sdk.window.startAutoResizer();

  // Helper function to find all linked entries
  const findEntries = React.useCallback(async (value: Array<SysLink> | undefined) => {
    const newLinkedEntries: Array<EntryPropsWithCustomMetaData> = [];

    await findAsync(
      value,
      async (item) => await cma.entry.get({ entryId: item.sys.id }).then(async (e) => {
        const entity = e as EntryPropsWithCustomMetaData;
        entity.custom = {};

        // Get information about the swatch if it exists. If it exists, we want
        // to fetch the asset so we have the actual data related to it rather
        // than just the ID.
        const { swatch } = entity.fields as Record<string, undefined | Record<string, Record<string, Record<string, string>>>>;
        if (swatch) {
          const localizedSwatch = swatch[sdk.locales.default];
          const assetId = localizedSwatch?.sys?.id;
          if (assetId) {
            await new Promise(r => setTimeout(r, 250)); // To avoid rate limiting
            await cma.asset.get({ assetId }).then((asset) => {
              entity.custom.swatchAsset = asset;
            });
          }
        } else {
          entity.custom.swatchAsset = undefined;
        }

        // Get information about the variant images if it exists. We don't need
        // the data for each of the related assets here right now, so instead
        // of fetching for each variant image (and related asset) for each
        // variant, we are just storing the raw link data for our replication.
        const { images } = entity.fields;
        entity.custom.images = images;

        newLinkedEntries.push(entity);
      })
    );

    setLinkedEntries(newLinkedEntries);
    return newLinkedEntries;
  }, [cma, sdk]);

  // Run once on start-up to pull the linked entries
  React.useEffect(() => { findEntries(sdk.field.getValue()) }, []);
  // Listen for changes, and re-populate and re-generate the linked entries
  useFieldChangeHook(sdk, findEntries);

  // Compare existing items already linked with the ones given from search, and
  // filter out duplicates.
  React.useEffect(() => {
    if (items?.length > 0) {
      // If we have items, but no linkedEntries yet, we can't do any filtering
      if (linkedEntries && linkedEntries?.length < 1) {
        setFilteredItems(items);
      } else {
        // If we have items AND linkedEntries, we can filter!
        const newFilteredItems = items.filter((item) => {
          const foundEntry = linkedEntries && linkedEntries.find((entry) => {
            if (entry?.fields?.sku) {
              if (typeof entry?.fields?.sku === 'string') {
                return entry.fields.sku === item.sku;
              } else {
                const skuLocales = entry.fields.sku;
                return skuLocales && skuLocales[sdk.locales.default] === item.sku;
              }
            }

            return false;
          });

          return typeof foundEntry === 'undefined';
        });

        setFilteredItems(newFilteredItems);
      }
    } else {
      setFilteredItems([]);
    }
  }, [items, linkedEntries, setFilteredItems, sdk]);

  // Search
  React.useEffect(() => {
    if (query !== '') {
      setLoadingState(Loading.Initialized);

      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `query itemSearchRaw($query: String!) { itemSearchRaw(query: $query) }`,
          variables: { query },
        }),
      }).then(res => res.json()).then(res => {
        const foundItems = res?.data?.itemSearchRaw || [];
        setItems(foundItems);
        setLoadingState(Loading.Success);
      }).catch(err => {
        console.error(err);
        setLoadingState(Loading.Error)
      });
    }
  }, [query]);

  // Add a variant
  const handleAddVariant = React.useCallback(async (item) => {
    // Check to see if the variant exists before creating
    const possibleEntries = await cma.entry.getMany({
      query: {
        content_type: 'variant',
        'fields.sku': item.sku,
      }
    });

    // If it exists, grab the first instance of it. If not, create a new one.
    const entry = possibleEntries?.total > 0 ? possibleEntries.items[0] : await cma.entry.create(
      {
        contentTypeId: 'variant'
      },
      {
        fields: {
          sku: {
            [sdk.locales.default]: item.sku,
          }
        }
      }
    );

    // Publish the variant
    await cma.entry.publish({ entryId: entry.sys.id }, entry);

    const existingFields = sdk.field.getValue() || [];

    // Merge the existing variants with the newly added variant
    sdk.field.setValue([
      ...existingFields,
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      }
    ])
  }, [sdk, cma]);

  /** Replication function */
  const replicate = React.useCallback(async (actions: Array<ReplicateAction>, fromId: string): Promise<void> => {
    // Lock the bulk editing menus until completed so we don't have multiple things going on at once
    setBulkActionsRunning(true);
    console.info(`Starting replication actions (${JSON.stringify(actions)}) from "${fromId}" to ${JSON.stringify(bulkEditIds)}`);

    // Force update entries before doing anything else
    const newLinkedEntries = await findEntries(sdk.field.getValue());

    try {
      const fromEntry = newLinkedEntries?.find((entry) => entry.sys.id === fromId);
      if (!fromEntry) {
        throw new Error(`Invalid fromId (not found): "${fromId}"`);
      }

      /** Replicate swatches */
      const replicateSwatch = async () => {
        console.info("Beginning replication of Swatch");
        const { swatchAsset } = fromEntry.custom;
        if (!swatchAsset) {
          // This shouldn't happen, but just in case!
          throw new Error("No swatch to replicate from");
        }

        for (const entryId of bulkEditIds) {
          // Make sure we don't edit the same exact ID
          if (entryId === fromId) {
            continue;
          }

          console.info(`Replicating Swatch to ${entryId} ...`);
          await new Promise(r => setTimeout(r, 100)); // To avoid rate limiting

          await patchEntryFieldReferences(
            cma,
            sdk,
            'swatch',
            {
              sys: {
                type: 'Link',
                linkType: 'Asset',
                id: swatchAsset.sys.id,
              }
            },
            entryId,
            sdk.locales.default,
          );
        }
      };

      /** Replicate variant images */
      const replicateVariantImages = async () => {
        console.info("Beginning replication of VariantImages");
        const { images } = fromEntry.custom;
        if (!images) {
          // This shouldn't happen, but just in case!
          throw new Error("No variant images to replicate from");
        }

        for (const entryId of bulkEditIds) {
          // Make sure we don't edit the same exact ID
          if (entryId === fromId) {
            continue;
          }

          console.info(`Replicating VariantImages to ${entryId} ...`);
          await new Promise(r => setTimeout(r, 100)); // To avoid rate limiting

          await patchEntryFieldReferences(
            cma,
            sdk,
            'images',
            images,
            entryId,
          );
        }
      };

      for (const action of actions) {
        switch (action) {
          case ReplicateAction.Swatch:
            await replicateSwatch();
            break;
          case ReplicateAction.VariantImages:
            await replicateVariantImages();
            break;
          default:
            throw new Error(`Unsupported replication action: "${action}"`);
        }
      }

      await new Promise(r => setTimeout(r, 500)); // To avoid rate limiting
      await findEntries(sdk.field.getValue());
    } catch (e) {
      console.error("Error while replicating:", e)
    } finally {
      // When we are done, we set that our bulk actions are set to false regardless of the outcome
      setBulkActionsRunning(false);
    }
  }, [bulkEditIds, cma, findEntries, sdk]);

  /** Bulk publish action function */
  const bulkPublish = React.useCallback(async () => {
    setBulkActionsRunning(true);

    try {
      for (const entryId of bulkEditIds) {
        const entry = linkedEntries?.find(e => e.sys.id === entryId);
        if (!entry) {
          continue;
        }

        await cma.entry.publish({ entryId }, entry);
      }

      await findEntries(sdk.field.getValue());
    } catch (e) {
      console.error("Error while publishing:", e)
    } finally {
      setBulkActionsRunning(false);
    }
  }, [bulkEditIds, cma, findEntries, linkedEntries, sdk]);

  /** Delegation function for bulk actions */
  const runBulkAction = React.useCallback(async (
    action: BulkAction,
    data?: ReplicateActionData
  ) => {
    switch (action) {
      case BulkAction.Replicate:
        if (!data) {
          throw new Error('Missing data, can not replicate');
        }

        return await replicate(data.actions, data.fromId);
      case BulkAction.Publish:
        return await bulkPublish();
      default:
        throw new Error(`Unsupported action type: "${action}"`);
    }
  }, [bulkPublish, replicate]);

  /** Wrapper for our custom renderer */
  const renderCustomCard = React.useCallback((
    props: CustomEntityCardProps,
    linkActionProps: LinkActionsProps,
    renderDefaultCard: DefaultCardRenderer
  ) => {
    return RenderCustomCardHook(
      props,
      linkActionProps,
      renderDefaultCard,
      linkedEntries,
      sdk,
      bulkEditIds,
      setBulkEditIds,
      runBulkAction,
      bulkActionsRunning,
    );
  }, [bulkEditIds, linkedEntries, sdk, bulkActionsRunning, runBulkAction]);

  return (
    <>
      {bulkActionsRunning &&
        <div className="bombas-loading-overlay">
          Working...
          <span>{sillyQuotes[Math.floor(Math.random()*sillyQuotes.length)]}</span>
        </div>
      }
      <Stack flexDirection="column" fullWidth={true}>
        <Card>
          <Paragraph>
            <Text>
              Search for variants to add to the product:&nbsp;
              <Text fontColor="gray500">
                (SKU, Style Number, Name, or Color)
              </Text>
            </Text>
          </Paragraph>
          <TextInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search..."
          />
        </Card>
        {loadingState === Loading.Initialized && (
          <SkeletonContainer ariaLabel="Loading potential variants">
            <SkeletonBodyText numberOfLines={4}/>
          </SkeletonContainer>
        )}
        {filteredItems?.length > 0 && (
          <EntityList style={{ width: '100%' }}>
            {filteredItems.map((item, index) => {
              const name = item?.name as string || undefined;
              const itemType = item?.type as string || undefined;
              const itemClass = item?.class as string || undefined;

              const info = itemType && itemClass ? `${itemType}, ${itemClass}` : undefined;

              return (
                <div
                  key={index}
                  style={{
                    position: 'relative'
                  }}
                >
                  <EntityList.Item
                    title={item.sku}
                    description={name}
                    contentType={info}
                    withThumbnail={false}
                  />
                  <div style={{
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    right: 0,
                    margin: '.75em'
                  }}>
                    <Button onClick={() => handleAddVariant(item)}>Add</Button>
                  </div>
                </div>
              );
            })}
          </EntityList>
        )}
        {linkedEntries !== undefined && (
          <div style={{ width: '100%' }}>
            <MultipleEntryReferenceEditor
              viewType="link"
              sdk={sdk}
              hasCardEditActions
              isInitiallyDisabled={false}
              parameters={{
                instance: {
                  showCreateEntityAction: true,
                  showLinkEntityAction: true,
                }
              }}
              renderCustomCard={renderCustomCard}
              // We need to force re-render because the custom card function is
              // wrapped in a memo and it's the only way for us to pass updated
              // state down to it. We are stringifying the passed down state to
              // be sure it's consistent with the changes to the expected state
              key={JSON.stringify([bulkEditIds, bulkActionsRunning])}
            />
            <Button size="small" style={{ marginTop: '20px', marginRight: '5px' }} onClick={() => setBulkEditIds([])}>Clear</Button>
            <Button size="small" style={{ marginTop: '20px', marginRight: '5px' }} onClick={() => setBulkEditIds(linkedEntries.map(e => e.sys.id))}>Select All</Button>
            <Button size="small" style={{ marginTop: '20px', marginRight: '5px' }} onClick={() => window.location.reload()}>Force Refresh</Button>
            <Button size="small" style={{ marginTop: '20px', marginRight: '5px' }} onClick={() => runBulkAction(BulkAction.Publish)}>Publish Variants</Button>
          </div>
        )}
      </Stack>
    </>
  );
};

export default ProductVariantsField;
