import React from 'react';
import { PlainClientAPI } from 'contentful-management';
import { SidebarExtensionSDK } from '@contentful/app-sdk';
import { Paragraph, SkeletonContainer, SkeletonBodyText } from '@contentful/f36-components';

interface VariantSidebarProps {
  sdk: SidebarExtensionSDK;
  cma: PlainClientAPI;
}

enum Loading {
  Initialized = 0,
  Success = 1,
  Error = 2,
}

interface Variant {
  [index: string]: unknown;
}

const endpoint = 'https://staging.platform.bombas.com/graphql';
// const endpoint = 'http://localhost:3000/dev/graphql';

const VariantSidebar = ({ sdk }: VariantSidebarProps) => {
  const [sku, setSku] = React.useState<string>(sdk.entry.fields.sku.getValue());
  const [loadingState, setLoadingState] = React.useState<Loading>(Loading.Initialized);
  const [variant, setVariant] = React.useState<Variant>();

  sdk.entry.onSysChanged(React.useCallback(() => {
    const id = sdk.entry.fields.sku.getValue();
    if (id !== sku) {
      setSku(id);
    }
  }, [sdk, sku, setSku]))

  React.useEffect(() => {
    sdk.window.startAutoResizer();
    setLoadingState(Loading.Initialized);

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query itemAsVariant($sku: String!) { itemAsVariant(sku: $sku) }`,
        variables: { sku },
      }),
    }).then(res => res.json()).then(res => {
      const data = res?.data?.itemAsVariant || {};
      setVariant(data);
      setLoadingState(Loading.Success);
      sdk.window.stopAutoResizer();
      sdk.window.updateHeight(500);
    }).catch(err => {
      console.error(err);
      setLoadingState(Loading.Error);
    })
  }, [sku]);

  if (loadingState === Loading.Initialized) {
    return (
      <SkeletonContainer ariaLabel="Loading variant data">
        <SkeletonBodyText numberOfLines={4}/>
      </SkeletonContainer>
    )
  } else if (loadingState === Loading.Error) {
    return (
      <SkeletonContainer backgroundColor="red" ariaLabel="ERROR LOADING">
        <SkeletonBodyText numberOfLines={4}/>
      </SkeletonContainer>
    )
  }

  return (
    <Paragraph>
      <pre>
        {JSON.stringify(variant, null, 2)}
      </pre>
    </Paragraph>
  );
};

export default VariantSidebar;
