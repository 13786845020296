import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import {
  SysLink,
  Entry,
  EntryProps,
  PlainClientAPI
} from 'contentful-management';
import { findAsync } from '../../utilities';
import { client, gql } from '../services/graphql';

type Action = Parameters<
  NonNullable<
    ComponentPropsWithoutRef<typeof MultipleEntryReferenceEditor>['onAction']
  >
>[0];

interface PageSectionsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const DEMO_PRODUCT_FEATURE_IDS = [
  '4IxQjW5GKT4wxKnx87pJMa',
  '35OpMwg40JLgWLwnS4cvpq',
  '2bacelmi5aYLfLijsdmbZO'
];

const PageSectionsField = ({ sdk, cma }: PageSectionsFieldProps) => {
  useEffect(() => {
    sdk.window.startAutoResizer();
  });

  const handleLinkProduct = async (value: Array<Entry>, entity: EntryProps) => {
    const newItems: SysLink[] = [];

    const productData = await client.request(
      gql`
        query getProduct($cmsId: ID!) {
          productByCmsId(id: $cmsId) {
            title
            gender
            categories
            variants {
              features {
                id
              }
            }
          }
        }
      `,
      { cmsId: entity.sys.id }
    );

    // Create CardContainer with first 3 product features
    // TODO: Get first 3 features from first variant via Bombas graph.
    if (productData?.productByCmsId?.variants[0].features.length) {
      const productTitle: string = await cma.entry
        .get({ entryId: sdk.entry.getSys().id })
        .then((e) => e.fields.title[sdk.locales.default]);
      const entry = await cma.entry
        .create(
          {
            contentTypeId: 'cardContainer'
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'productFeaturedTech'
                  }
                }
              ]
            },
            fields: {
              title: {
                [sdk.locales.default]: `${productTitle} Featured Tech`
              },
              cards: {
                [sdk.locales.default]: DEMO_PRODUCT_FEATURE_IDS.map(
                  (featureId) => ({
                    sys: {
                      type: 'Link',
                      linkType: 'Entry',
                      id: featureId
                    }
                  })
                )
              }
            }
          }
        )
        .then((e) => cma.entry.publish({ entryId: e.sys.id }, e));
      newItems.push({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      });
    }

    const donationModules: Array<EntryProps> = await cma.entry
      .getMany({ query: { 'metadata.tags.sys.id[all]': 'donation' } })
      .then((r) => r.items || []);

    // Excludes donation module from being added to products like giftcards, bags, and other addon items.
    if (productData.productByCmsId.gender) {
      const defaultModule = donationModules.find((mod) =>
        mod.fields.title[sdk.locales.default].toLowerCase().includes(`default`)
      );
      // Find donation module matching gender_c1 (single) or gender_c1|c2 (multi-category) structure.
      // NOTE: Categories are sorted A-Z since order does not change the relevant module.
      const relevantModule = donationModules.find((mod) =>
        mod.fields.title[sdk.locales.default]
          .toLowerCase()
          .includes(
            `${
              productData.productByCmsId.gender
            }_${productData.productByCmsId.categories.join('|').sort()}`.toLowerCase()
          )
      );
      const entityId = relevantModule?.sys.id || defaultModule?.sys.id;

      if (entityId) {
        newItems.push({
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: entityId
          }
        });
      }
    }

    if (newItems.length) {
      sdk.field.setValue([...(value || []), ...newItems]);
    }
  };

  const handleAction = async (action: Action) => {
    if (
      action.type === 'create_and_link' ||
      action.type === 'select_and_link'
    ) {
      const actionEntityIsProduct =
        action.entityData.sys.contentType.sys.id === 'product';

      if (actionEntityIsProduct) {
        const values = sdk.field.getValue();
        const hasProductLink = await findAsync(
          values.filter(
            (link: EntryProps) => link.sys.id !== action.entityData.sys.id
          ),
          async (item: EntryProps) =>
            await cma.entry.get({ entryId: item.sys.id }).then((e) => {
              if (e.sys.contentType.sys.id === 'product') return true;
              return false;
            })
        );

        // If first product was added, add dynamic modules.
        if (!hasProductLink)
          return await handleLinkProduct(values, action.entityData);
      }
    }
  };

  return (
    <MultipleEntryReferenceEditor
      viewType="link"
      sdk={sdk}
      hasCardEditActions
      isInitiallyDisabled={false}
      parameters={{
        instance: { showCreateEntityAction: true, showLinkEntityAction: true }
      }}
      onAction={handleAction}
    />
  );
};

export default PageSectionsField;
