import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI, Link, EntryProps } from 'contentful-management';

export const getEntry = async (cma: PlainClientAPI, entryId: string): Promise<EntryProps> => {
  return await cma.entry.get({ entryId });
};

/** Update tags on an entry */
export const patchEntryTags = async (
  cma: PlainClientAPI,
  sdk: FieldExtensionSDK,
  tags: Array<Link<"Tag">>
): Promise<EntryProps> => {
  const sys = sdk.entry.getSys();
  return await cma.entry.patch(
    { entryId: sys.id },
    [{ path: '/metadata/tags', op: 'replace', value: tags }],
    { 'X-Contentful-Version': sys.version }
  );
};

/**
 * Update a field's single reference. If `entryId` is set, it will utilize the
 * given entry to be updated rather than the current entry that is open. If
 * `locale` is set, it will setup the patch call to utilize localization, and
 * if not then it will not use localization. Make sure to update based 
 */
export const patchEntryFieldReferences = async (
  cma: PlainClientAPI,
  sdk: FieldExtensionSDK,
  field: string,
  value: Link<'Entry' | 'Asset'> | Array<Link<'Entry' | 'Asset'>>,
  entryId: string | undefined = undefined,
  locale: string | undefined = undefined,
): Promise<EntryProps> => {
  const sys = entryId ? (await (await getEntry(cma, entryId)).sys) : sdk.entry.getSys();
  const patchValue = locale ? { [locale]: { ...value } } : value;

  return await cma.entry.patch(
    { entryId: sys.id },
    [{ path: `/fields/${field}`, op: 'add', value: patchValue }],
    { 'X-Contentful-Version': sys.version }
  );
};