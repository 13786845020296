import React, { useEffect, useState, useMemo, ChangeEvent } from 'react';
import {
  Flex,
  Card,
  Box,
  Button,
  IconButton,
  TextInput,
  FormControl,
  Select,
  Stack,
  Note,
  Text
} from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { debounce } from 'lodash';
import { client, gql } from '../services/graphql';

interface ProductCardFeaturedColorsFieldProps {
  sdk: FieldExtensionSDK;
}
interface FeaturedColor {
  id: string;
  color: string;
  badge?: string;
}

const ProductCardFeaturedColorsField = ({
  sdk
}: ProductCardFeaturedColorsFieldProps) => {
  const [values, setValues] = useState<Array<FeaturedColor>>(
    sdk.field.getValue() || []
  );
  const [productLink, setProductLink] = useState(
    sdk.entry.fields.product.getValue()
  );
  const [colorOptions, setColorOptions] = useState<Array<{ id: string }>>([]);
  const debouncedPushValue = useMemo(
    () => debounce((value) => sdk.field.setValue(value), 500),
    [sdk.field]
  );
  const setContentfulValue = (val: Array<FeaturedColor>) => {
    // Push data update to Contentful when values change (exclude unconfigured options)
    const sanitizedOptions = val.filter((v) => v.color !== 'invalid');
    // Purposefully not using debounce here so UI has immediate feedback.
    if (!sanitizedOptions.length) sdk.field.setValue(undefined);
    else debouncedPushValue(sanitizedOptions);
  };
  const handleAddRow = () => {
    setValues([
      ...values,
      {
        id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
        color: 'invalid',
        badge: ''
      }
    ]);
  };
  const handleDeleteRow = (deleteId: string) =>
    setValues((prevState) => {
      const next = prevState.filter(({ id }) => id !== deleteId);
      setContentfulValue(next);
      return next;
    });
  const handleUpdateRow = (
    updateId: string,
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setValues((prevState) => {
      const next = prevState.map((obj) => {
        if (updateId === obj.id)
          return { ...obj, [event.target.name]: event.target.value };
        return obj;
      });
      setContentfulValue(next);
      return next;
    });
  };
  const isFieldDisabled = !productLink || !colorOptions.length;

  // Update height of field whenever new rows are added
  useEffect(() => {
    sdk.window.updateHeight();
  }, [sdk.window, values.length, isFieldDisabled]);

  // Fetch color options on load
  useEffect(() => {
    async function fetchColors() {
      const response = await client.request(
        gql`
          query getProductColors($cmsId: ID!) {
            productByCmsId(id: $cmsId) {
              colors {
                id
              }
            }
          }
        `,
        { cmsId: productLink.sys.id }
      );

      setColorOptions(response.productByCmsId.colors);
    }

    // Query GQL for Product by CMS_ID
    if (productLink?.sys?.id) {
      fetchColors();
    }
  }, [productLink]);

  // Listen for changes to `product` field.
  useEffect(() => {
    const unsubscribe = sdk.entry.fields.product.onValueChanged((value) => {
      setProductLink(value);
    });

    return () => {
      unsubscribe();
    };
  }, [sdk.entry.fields.product, sdk.field.onValueChanged]);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      {isFieldDisabled && (
        <Note variant="warning">
          {!productLink
            ? 'You must link a product before configuring featured colors.'
            : 'No color options were found on this product.'}
        </Note>
      )}
      {values.map(({ id, color, badge }) => (
        <Card key={id}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap="spacingS"
          >
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Color</FormControl.Label>
              <Select
                name="color"
                value={color}
                onChange={(e) => handleUpdateRow(id, e)}
              >
                <Select.Option value="invalid" isDisabled>
                  Pick a color
                </Select.Option>
                {colorOptions.map(({ id: colorId }) => {
                  return (
                    <Select.Option value={colorId} key={colorId}>
                      {colorId}
                    </Select.Option>
                  );
                })}
                {color !== 'invalid' &&
                  !colorOptions.find(
                    ({ id: colorId }) => colorId === color
                  ) && (
                    <Select.Option value={color}>
                      {color} (Unavailable)
                    </Select.Option>
                  )}
              </Select>
            </Box>
            <Box style={{ width: '50%' }}>
              <FormControl.Label>Badge (optional)</FormControl.Label>
              <TextInput
                name="badge"
                value={badge}
                onChange={(e) => handleUpdateRow(id, e)}
                isDisabled={color === 'invalid' || isFieldDisabled}
              />
            </Box>
            <IconButton
              variant="transparent"
              aria-label="Delete row"
              icon={<DeleteIcon />}
              isDisabled={isFieldDisabled}
              onClick={() => handleDeleteRow(id)}
            />
          </Flex>
        </Card>
      ))}
      <Button onClick={handleAddRow}>Add item</Button>
      <Text fontColor="gray500" fontSize="fontSizeS">
        Colors marked as <i>(Unavailable)</i> will not be shown on the site.
      </Text>
    </Stack>
  );
};

export default ProductCardFeaturedColorsField;
