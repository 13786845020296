/**
 * Asynchronous implementation of Array.find which executes async functions in parallel.
 * @param array List to iterate
 * @param cb Async callback function
 * @returns Promise | undefined
 */
export async function findAsync<T>(
  array: Array<T> = [],
  cb: (element: T, index: number) => PromiseLike<unknown>
): Promise<T | undefined> {
  const results = await Promise.all(array.map(cb));
  return array[results.findIndex((result) => result)];
}
